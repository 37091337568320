import React from 'react'
import ReactDOM from 'react-dom/client'
import './style.css'
import { App } from './App'
import WebFont from 'webfontloader'

WebFont.load({
      google: {
            families: [ 'Roboto:400,700', 'Jost:900italic' ]
      }
})


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
      <React.StrictMode>
            <App />
      </React.StrictMode>
)
