function isHostAllowed() {
      if (window.location.host === "onno.co.uk") { return true } else { return false }
}

export function App() {

      if (isHostAllowed()) {

            return (
                  <div className="App">
                        <div class="container">
                              <div>
                                    <img style={{ display: 'block', width: 250 }} src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
                              </div>
                              <div style={{ color: 'white', marginLeft: 50, paddingLeft: 25, borderLeft: '2px solid rgba(255,255,255,0.1)' }}>
                                    2nd Floor Northgate House<br />
                                    Upper Borough Walls<br />
                                    Bath<br />
                                    BA1 1RG<br />
                              </div>
                        </div>
                  </div>
            )

      }

      else {

            return (
                  <div className="App">
                        <div class="container">
                              <h1>whoops</h1>
                        </div>
                  </div>
            )

      }
}
